<script setup lang="ts">
const query = defineModel({ default: '', type: String })
const emit = defineEmits(['focus'])

const localeRoute = useLocaleRoute()
const formId = useId()
const inputId = useId()

const { t } = useI18n()
const formAction = computed(() =>
  localeRoute({
    name: ROUTE_NAMES.SEARCH_PAGE,
  }),
)

defineOptions({
  name: 'SearchAutosuggestForm',
})
</script>

<template>
  <FormKit
    :id="formId"
    type="form"
    name="searchForm"
    :actions="false"
    :action="formAction?.fullPath"
  >
    <FormKit
      v-model="query"
      :id="inputId"
      name="search"
      type="search"
      autocomplete="off"
      :delay="200"
      :placeholder="t('placeholder')"
      :classes="{
        outer: 'dark:text-neutral-light-1 text-neutral-dark-1',
        inner:
          'border-none text-sm lg:text-sm !bg-neutral-light-2 dark:!bg-header-dropdown !outline-none',
        icon: '!fill-inherit',
        input:
          'focus:!text-dark focus:!bg-white focus:!outline-none !md:text-sm',
      }"
      @focus="emit('focus')"
    />
  </FormKit>
</template>

<i18n>
de:
  placeholder: "Film, Kino, Stadt"
es:
  placeholder: "Película, cine, ciudad"
</i18n>
